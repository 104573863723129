.footer {
  width: 100%;
}
.footer .footer-apply-box {
  position: relative;
  background: #F8F8F8;
}
.footer .footer-apply-box .toast-box {
  position: absolute;
  font-size: 0.1875rem;
  color: #FFFFFF;
  width: 1.52604rem;
  height: 0.46875rem;
  background: rgba(1, 1, 1, 0.6);
  border-radius: 0.07813rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  margin-top: -0.23438rem;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 5;
}
.footer .footer-apply-box-content {
  background: linear-gradient(-25deg, #ff705d, #de59a8);
  padding: 0.33333rem 0 0.45313rem;
}
.footer .footer-apply-box-title {
  color: #ffffff;
  font-size: 0.1875rem;
  text-align: center;
  margin-bottom: 0.17708rem;
}
.footer .footer-apply-box-logo {
  margin-bottom: 0.04688rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.23958rem;
}
.footer .footer-apply-box-logo > img {
  width: 1.45833rem;
  height: 0.25521rem;
  margin-right: 0.05208rem;
  margin-top: 0.01563rem;
}
.footer .footer-apply-box-logo > span {
  font-size: 0.23958rem;
}
.footer .footer-apply-box-body {
  width: 4.66667rem;
  margin: 0 auto;
}
.footer .footer-apply-box-body .footer-apply-box-input-wrap {
  display: flex;
}
.footer .footer-apply-box-body input {
  width: 100%;
  margin-top: 0.11979rem;
  height: 0.27083rem;
  border-radius: 0.02083rem;
  text-indent: 0.15625rem;
  font-size: 0.09375rem;
  color: #333333;
}
.footer .footer-apply-box-body .your-name {
  width: 2.29167rem;
}
.footer .footer-apply-box-body .your-phone {
  width: 2.29167rem;
  margin-left: auto;
}
.footer .footer-apply-box-body .introduction {
  width: 4.63542rem;
  margin-top: 0.11979rem;
  height: 1.06771rem;
  text-indent: 0.15625rem;
  border-radius: 0.02083rem;
  padding-top: 0.05208rem;
  text-align: top;
  resize: none;
  font-size: 0.09375rem;
  line-height: 0.14583rem;
}
.footer .footer-apply-box-submit {
  text-align: center;
  background: #fff;
  margin: 0 auto;
  margin-top: 0.26042rem;
  color: #454545;
  font-size: 0.08333rem;
  width: 0.88542rem;
  height: 0.22917rem;
  line-height: 0.22917rem;
  box-shadow: 0 0.05208rem 0.06771rem 0 rgba(171, 19, 13, 0.32);
  border-radius: 0.11458rem;
  cursor: pointer;
  transition: 0.2s transform linear;
  -webkit-transition: 0.2s transform linear;
}
.footer .footer-apply-box-submit:hover {
  transform: translate3d(0, -0.01042rem, 0);
  font-weight: bold;
  color: #FFFFFF;
  background: linear-gradient(-90deg, #ff705d, #de59a8);
}
.footer .footer-contact {
  padding: 1.17708rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #A6A6A6;
}
.footer .footer-contact .contact-us {
  width: 100%;
  position: absolute;
  font-size: 0.9375rem;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s all linear;
}
.footer .footer-contact .contact-us.floatUp {
  transform: translateY(-51.5%);
}
.footer .footer-contact .contact-us-chinese {
  font-size: 1.09375rem;
  margin-top: 0.10417rem;
}
.footer .footer-contact .contact-us > div {
  color: #fff;
  text-shadow: 0 0.0625rem 0.17188rem rgba(0, 0, 0, 0.14);
  font-weight: 800;
}
.footer .footer-contact .shadow {
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.77;
  position: absolute;
}
.footer .footer-contact-top {
  font-size: 0.10417rem;
  z-index: 99;
  color: #ffffff;
  align-items: center;
}
.footer .footer-contact-top > img {
  width: 0.82292rem;
  height: 0.11979rem;
  margin-top: 0.01563rem;
}
.footer .footer-contact-top > p {
  margin-left: 0.05208rem;
}
.footer .footer-contact-phone {
  width: 2.54688rem;
  height: 0.57292rem;
  line-height: 0.57292rem;
  background: linear-gradient(-90deg, #13d1a8, #23b7e4);
  box-shadow: 0 0.00521rem 0.05208rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 0.28646rem;
  font-size: 0.14583rem;
  font-weight: bold;
  color: #ffffff;
  margin: 0.33333rem 0 0.25521rem 0;
  z-index: 99;
  transition: 0.3s all linear;
}
.footer .footer-contact-phone:hover {
  cursor: pointer;
  transform: translate3d(0, -0.02083rem, 0);
}
.footer .footer-contact-email {
  font-weight: 400;
  font-size: 0.10417rem;
  z-index: 99;
}
.footer .footer-body {
  background: #242526;
  color: #fff;
}
.footer .footer-body .footer-nav-list {
  display: flex;
  padding: 0.66667rem 0.33333rem 0.44271rem 0.33333rem;
  width: 6.14583rem;
  margin: 0 auto;
}
.footer .footer-body .footer-nav-list-item {
  flex: 1;
  white-space: nowrap;
}
.footer .footer-body .footer-nav-list-item .facebook-icon {
  margin-right: 0.07813rem;
}
.footer .footer-body .footer-nav-list-item-list-item {
  margin-bottom: 11PX;
  color: #fff;
  height: auto;
  cursor: pointer;
}
.footer .footer-body .footer-nav-list-item-list-item > a,
.footer .footer-body .footer-nav-list-item-list-item span {
  font-size: 0.07292rem;
  color: #737D82;
}
.footer .footer-body .footer-nav-list-item-list-item.main {
  margin-bottom: 0.15625rem;
  position: relative;
}
.footer .footer-body .footer-nav-list-item-list-item.main > a {
  font-size: 0.09375rem;
  font-weight: bold;
  line-height: 0.11458rem;
  color: #fff;
}
.footer .footer-body .footer-nav-list-item-list-item.main > a .hovering {
  position: absolute;
  bottom: -0.02604rem;
  right: 58%;
  width: 0.17708rem;
  height: 0.01563rem;
  background: #242526;
  transition: all 0.5s;
}
.footer .footer-body .footer-nav-list-item-list-item.main > a .hovering.active {
  background: linear-gradient(-90deg, #13d1a8, #23b7e4);
}
.footer .footer-body .footer-nav-list-item-list-item.main > a:hover > .hovering {
  background: linear-gradient(-90deg, #13d1a8, #23b7e4);
}
.footer .footer-body .footer-bottom {
  padding: 0.58854rem 0 0.60938rem 0;
  text-align: center;
  border-top: 0.00521rem solid #A6A6A6;
  font-size: 0.16667rem;
}
.footer .footer-body .footer-bottom-small {
  margin-top: 0.21354rem;
  font-size: 0.09375rem;
  text-shadow: 0 0.01042rem 0.10417rem rgba(0, 0, 0, 0.14);
}
.footer .footer-body .footer-bottom-big.hide {
  display: none;
}
.footer .footer-body .footer-bottom-big > img {
  width: 1.09375rem;
  height: 0.16667rem;
  margin-top: 0.02604rem;
}
.footer .footer-body .footer-bottom-big > p {
  font-size: 0.16667rem;
  font-weight: bold;
  letter-spacing: 0.01042rem;
}
.footer .footer-body .footer-bottom-big > p.en {
  margin: 0 0.05729rem;
}
.footer .footer-body .footer-copyright {
  text-align: center;
  height: 0.65625rem;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.09375rem;
  line-height: 0.10417rem;
  background: #333333;
}
.footer .footer-body .footer-copyright a {
  color: #00d7ff;
  margin-left: 0.02604rem;
}
