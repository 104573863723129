.header-garybar {
  position: fixed;
  width: 100%;
  background: #e5e5e5;
  text-align: center;
  color: #252525;
  font-size: 0.0625rem;
  height: 40PX;
  line-height: 40PX;
  transition: all 0.5s;
  z-index: 9;
}
.header {
  width: 100%;
  position: fixed;
  top: 0;
  height: 0.52083rem;
  z-index: 200;
  transition: all 0.5s;
}
.header.top {
  top: 40PX;
}
.header.transparent {
  background: rgba(89, 114, 141, 0.12);
}
.header-body {
  width: 6.25rem;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.header-body-logo {
  position: relative;
  width: 0.60417rem;
  height: 0.30208rem;
  outline: none;
  line-height: 0.30208rem;
}
.header-body-logo > img {
  width: 0.60417rem;
  height: 0.30208rem;
}
.header-body-nav {
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
.header-body-nav-item {
  margin-left: 0.26042rem;
  position: relative;
  height: 0.3125rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header-body-nav-item:first-child {
  margin: 0;
}
.header-body-nav-item-link {
  color: #fff;
  font-size: 0.09375rem;
  font-weight: bold;
  height: 100%;
  line-height: 0.3125rem;
  outline: none;
  cursor: pointer;
  display: inline-block;
}
.header-body-nav-item-link.active,
.header-body-nav-item-link:hover {
  color: #00d7ff;
}
.header-body-position {
  margin-left: 0.28646rem;
  padding: 0.02083rem 0.06771rem;
  background: #000000;
  align-items: center;
  position: relative;
}
.header-body-position > img {
  width: 0.09375rem;
  height: 0.09375rem;
  border-radius: 50%;
  margin-right: 0.03646rem;
}
.header-body-position > span {
  color: #fff;
  font-size: 0.0625rem;
  transform: scale(0.9);
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.8);
}
.header-body-position > .right-double {
  width: 0.03646rem;
  height: 0.04167rem;
  background-image: url(http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fright-double.png);
  background-size: 100% 100%;
}
.header-body-position .site-list {
  position: absolute;
  width: 100%;
  z-index: 9;
  top: 0.13542rem;
  left: 0;
  box-shadow: 0 0.04167rem 0.10417rem 0 rgba(43, 47, 51, 0.15);
  display: none;
}
.header-body-position .site-list-item {
  font-size: 0.07292rem;
  text-align: center;
  line-height: 0.25rem;
  background: #FFF;
  cursor: pointer;
}
.header-body-position .site-list-item > a {
  display: block;
}
.header-body-position .site-list-item:hover {
  background-color: #E9F0F2;
}
.header-body-position:hover .site-list {
  display: block;
}
.header-body-contact-btn {
  font-size: 0.07292rem;
  font-weight: bold;
  padding: 0.03646rem 0.09896rem 0.04167rem 0.11458rem;
  background: linear-gradient(0deg, #1e9df7, #31e9ff);
  border-radius: 0.10417rem;
  color: #FFFFFF;
  display: flex;
  align-items: center;
}
.header-body-contact-btn > img {
  width: 0.05729rem;
  height: 0.0625rem;
  margin-left: 0.04688rem;
}
.header-body-contact-btn:hover {
  transform: translate3d(0, -0.00521rem, 0);
  font-weight: bold;
  transition: 0.2s all linear;
  -webkit-transition: 0.2s all linear;
  background: linear-gradient(0deg, #11fc83, #31e9ff);
  box-shadow: 0 0.01042rem 0.05208rem 0 rgba(255, 255, 255, 0.3);
}
.header-tel {
  display: flex;
  align-items: center;
  color: #fff;
}
.header-tel img {
  width: 0.07292rem;
  height: 0.09375rem;
  margin-right: 0.01563rem;
}
.header-tel-num {
  font-size: 0.125rem;
  font-weight: bold;
  margin-right: 0.10417rem;
}
.header-tel span {
  font-size: 0.125rem;
  margin-right: 0.05208rem;
}
.header-tel-hotline {
  border: 0.00521rem solid #fff;
  font-size: 0.07292rem;
  padding: 0.02083rem 0.05208rem;
  border-radius: 0.02083rem;
}
